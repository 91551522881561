import React, { useEffect, useReducer, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import reducer from '@hooks/useAuth/reducer'
import useSessionStorage from '@hooks/useSessionStorage'
import axiosInstance from '@services/axiosInstance'
import Cookies from 'js-cookie'
import PropTypes from 'prop-types'
import moment from 'moment'
import _get from 'lodash/get'
import _trimStart from 'lodash/trimStart'
import { AuthContext } from './context'
import { PATHS } from '../../Routes'
// import Window from 'components/Alert/AlertForm'

AuthProvider.propTypes = {
  children: PropTypes.any
}

// eslint-disable-next-line
export default function AuthProvider ({ children }) {
  const { push } = useHistory()
  const { pathname } = useLocation()
  const [, setSession] = useSessionStorage('session', null)
  const [profile, setProfile] = useSessionStorage('profile', {})
  const [ready, setReady] = useState(false)
  const [accessToken, setAccessToken] = useSessionStorage('accessToken', null)
  const [customerKey, setCustomerKey] = useSessionStorage('customerKey', null)

  const [state, dispatch] = useReducer(reducer, {
    profile,
    customerKey,
    accessToken,
    isAuthenticated: accessToken && customerKey && _get(profile, 'id'),
    time: moment().format('DD-MM-YYYY, HH:mm')
  })
  const currentProfileId = _trimStart(_get(Cookies.get(), 'CustomerId'), '0') || _get(state, 'profile.id', '')
  // recupero respuesta de servicio tricor01 con la Cookie "CustomerKey"
  const currentCustomerKey = _get(Cookies.get(), 'CustomerKey') || _get(state, 'customerKey', null)
  const currentAccessToken = _get(Cookies.get(), 'AccessToken') || _get(state, 'accessToken', null)
  // creo una constante para convertir la respuesta del servicio tricor01 para obtener sus datos y utilizarlos
  const loginResponse = JSON.parse(currentCustomerKey)
  const campaign = _get(Cookies.get(), 'Campaign')
  const amount = _get(Cookies.get(), 'Amount')
  console.log('🚀 ~ AuthProvider ~ campaign:', campaign)
  console.log('🚀 ~ AuthProvider ~ amount:', amount)
  const updateAuthProfile = async ({ profileId, customerKey, accessToken }) => {
    axiosInstance.defaults.headers.common['customer-key'] = customerKey
    axiosInstance.defaults.headers.common.Authorization = `bearer ${accessToken}`
    const response = await axiosInstance.get(`/v1/customers/${profileId}`)
    const updatedProfile = Object.assign(
      {},
      _get(state, 'profile', {}),
      _get(response, 'data.customer', {}),
      {
        id: profileId,
        identificationNumber: _trimStart(_get(response, 'data.customer.identificationNumber', ''), '0'),
        executiveCode: 1234, // Código ejecutivo
        branchCode: 711 // Código sucursal
      }
    )
    setProfile(updatedProfile)
    // actualizo el customerKey con la respuesta del servicio y el dato respectivo del token
    setCustomerKey(loginResponse.token)
    setAccessToken(accessToken)
    setSession({ accessToken })
    await dispatch({
      action: 'update',
      values: {
        accessToken,
        customerKey,
        profile: updatedProfile,
        isAuthenticated: accessToken && customerKey && _get(updatedProfile, 'id'),
        time: moment().format('DD-MM-YYYY, HH:mm')
      }
    })
  }

  useEffect(() => {
    // eslint-disable-next-line max-statements
    const handleAuth = async () => {
      try {
        if (currentProfileId) {
          await updateAuthProfile({
            profileId: currentProfileId,
            accessToken: currentAccessToken,
            // actualizo el customerKey con la respuesta del servicio y el dato respectivo del token
            customerKey: loginResponse.token,
            time: moment().format('DD-MM-YYYY, HH:mm')
          })
          if (['/', PATHS.AUTH].includes(pathname)) {
            push(PATHS.HOME)
          }
          if (loginResponse.mustUpdatePassword === true) {
            push(PATHS.MUSTUPDATEPASSWORD)
          } else if (loginResponse.shouldUpdatePassword === true) {
            push(PATHS.MUSTUPDATEPASSWORD)
          }
          if (campaign === 'reo') {
            push(PATHS.RENEGOTIATION_DEBT)
          }
        } else {
          setProfile({})
        }
      } catch (e) {
        setSession(null)
        dispatch({ action: 'reset' })
      }
    }
    handleAuth()
  // eslint-disable-next-line
  }, [currentProfileId])
  // useEffect(() => {
  //   if (profile.campaignCode === 2 || profile.campaignCode === 1) {
  //     setIsWindowVisible(true)
  //   }
  // }, [profile.campaignCode])
  useEffect(() => {
    setReady(true)
  }, [])
  // const [isWindowVisible, setIsWindowVisible] = useState(false)

  // const closeWindow = () => {
  //   setIsWindowVisible(false)
  // }

  return (
    <AuthContext.Provider value={[state, dispatch]}>
      {/* isWindowVisible && <Window open={ isWindowVisible === true } onClose={closeWindow} /> */}
      {ready ? children : []}
    </AuthContext.Provider>
  )
}
